.row-token__container {
  padding: 4px 24px;

  display: flex;
  align-items: center;

  cursor: pointer;

  height: 40px;
  &:not(.row-token__container--selected):hover {
    transition: background-color 0.2s;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &--selected {
    background-color: var(--tg-theme-secondary-bg-color);
  }
  .row-token {
    &__token-logo {
      margin-left: 16px;

      height: 100%;
      width: auto;
    }
    &__name {
      margin-left: 8px;

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: var(--tg-theme-text-color);
    }
  }
}