.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip__content {
  visibility: hidden;
  min-width: 120px;
  background-color: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-hint-color);
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 8px);
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltip__content {
  visibility: visible;
}

.tooltip__content {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}