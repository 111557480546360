.select-token-for-pay__container {
  padding-bottom: 62px;
}

.select-token-for-pay__list-tokens__container {
  border: 1px solid var(--tg-theme-hint-color);
  border-radius: 4px;

  padding: 20px 0;
}

.select-token-for-pay__total-amount-and-commission {
  margin-top: 24px;
}

.select-token-for-pay__commission {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: var(--tg-theme-hint-color);
}

.select-token-for-pay__total-amount {
  margin-top: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: var(--tg-theme-text-color);
}

.modal-state__container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.modal-state__message {
  white-space: pre-line;
  text-align: center;

  font-size: 16px;
  line-height: 19px;

  color: var(--tg-theme-text-color);
}

.modal-state__state {
  margin: auto;
  margin-top: 16px;

  width: 50px;
  height: 50px;

  &--icon {
    width: 100%;
  }
}