.product__wrapper {
  height: fit-content;
  position: relative;

  padding-bottom: 115px;

  &__with-action {
    padding-bottom: 172px;
  }
}

.product__container {
  height: 100%;

  display: flex;
  flex-direction: column;

  .product__content {
    height: 100%;

    .product__img {
      width: auto;
      max-width: 100%;
      height: auto;

      border-radius: 4px;

      &--plug {
        height: 266px;
        background: #CDD0E0;
      }
    }

    .product__title {
      margin-top: 24px;

      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }

    .product__description {
      margin-top: 24px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      white-space: pre-line;
    }
  }

  .product__price-and-add {
    width: 100%;

    position: fixed;

    bottom: 0;
    left: 0;

    &__count {
      &__wrapper {
        padding: 16px 24px 8px;
        background: var(--tg-theme-bg-color);

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
      }
      &__action {
        height: 30px;

        border-radius: 40px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        background: var(--tg-theme-button-color);
        color: var(--tg-theme-button-text-color);

        &__minus, &__add {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #FFFFFF;
          background: var(--tg-theme-hint-color);
          color: var(--tg-theme-bg-color);

          display: flex;
          justify-content: center;
          align-items: center;

          cursor: pointer;
        }
        &__minus {
          margin-right: 12px;
        }
        &__add {
          margin-left: 12px;
        }
      }
    }

    .product__price {
      padding: 8px 24px;
      background: var(--tg-theme-bg-color);

      display: flex;
      justify-content: space-between;
      align-items: center;

      &--none-padding {
        padding: 0;
      }

      &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;

        &--bold {
          font-weight: 700;
        }
      }
    }

    .product__btn-add {
      cursor: pointer;

      border-radius: 4px;

      width: 40%;
      padding: 12px 0;

      background: var(--tg-theme-button-color);
      color: var(--tg-theme-button-text-color);

      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: center;

      &--full-width {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}