.select-token-for-pay__commission__text {
  display: flex;
}

.select-token-for-pay__commission__tooltip {
  margin-left: 8px;

  &-icon {
    width: 17px;
    height: 17px;
  }
}