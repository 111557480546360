.catalog {
  &__wrapper {
    height: 100%;
    width: 100%;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;

    padding-bottom: 52px;
  }
  &__scripts {
    display: flex;
    flex-wrap: wrap;

    padding-bottom: 10px;
  }
  &__form {
    display: grid;
    grid-gap: .5rem;
  
    width  : 350px;
    margin : 0 auto;
  }
  &__scripts textarea, input {
    display : block;
    box-sizing: border-box;
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 100%;
    height: 100%;
  }
  &__loading {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    &__box {
      width: 64px;
      height: 64px;
    }
  }
}

.modal-state__container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.modal-state__message {
  white-space: pre-line;
  text-align: center;

  font-size: 16px;
  line-height: 19px;

  color: var(--tg-theme-text-color);
}

.modal-state__state {
  margin: auto;
  margin-top: 16px;

  width: 50px;
  height: 50px;

  &--icon {
    width: 100%;
  }
}