@import '../../index';

.not-found {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100vh;
  }
  &__icon {
    width: 80px;
    height: auto;
  }
  &__header {
    margin-top: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
  }
  &__description {
    margin-top: 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &__action {
    margin-top: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  p {
    margin: 0;
    padding: 0;

    color: var(--tg-theme-text-color);
  }
  a {
    color: var(--tg-theme-link-color);
  }
}