.select-payment-method__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select-pay-method__item {
  cursor: pointer;

  width: 45%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--tg-theme-secondary-bg-color);

  &:first-child {
    margin-right: 24px;
  }
  &__title {
    margin-top: 18px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}