.card {
  &__container {
    width: calc(90% / 3);
    height: 180px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:not(:nth-child(-n + 3)) {
      margin-top: 32px;
    }
    &:not(:nth-child(3n + 3)) {
      margin-right: 5%;
    }
  }
  &__item {
    &__img {
      max-width: 100%;
      height: 100%;
      max-height: 72px;
      object-fit: cover;

      border-radius: 4px;
    }
    &__text {
      margin-top: 8px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__btn {
      cursor: pointer;

      margin-top: 8px;
      padding: 6px 0;

      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--tg-theme-button-color);
      color: var(--tg-theme-button-text-color);
      &--with-action {
        border-radius: 40px;

        padding: 0;

        display: flex;
        justify-content: space-between;
        &__minus, &__add {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #FFFFFF;
          background: var(--tg-theme-hint-color);
          color: var(--tg-theme-bg-color);

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &__text__title {
      font-weight: 500;
    }
  }

  &__img--plug {
    background: #CDD0E0;
  }
}