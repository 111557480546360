.main-wrapper {
  display: flex;
  justify-content: center;

  .main-container {
    height: 100vh;
    width: 100vw;

    max-width: 834px;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    position: relative;
    &__header {
      display: flex;

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      padding: 0px 20px;
      &__back {
        width: 20px;
        height: 20px;

        margin-top: 2px;
        margin-right: 16px;

        cursor: pointer;
      }
    }
    &__pay {
      cursor: pointer;

      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 12px 0;

      background: var(--tg-theme-button-color);
      color: var(--tg-theme-button-text-color);

      position: fixed;
      z-index: 10;
      bottom: 0;

      width: 100%;
    }
    &__container {
      margin: 0 24px 24px 24px;

      height: 100%;
    }
    &__pay {
      &--loading {
        width: 30px;
        height: 30px;
      }
    }
  }
}
