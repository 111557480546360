.overlaying-popup__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 24px;
  box-sizing: border-box;

  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  .overlaying-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}
