.card-cart {
  &__container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;;
  }

  &__left-part, &__right-part {
    display: flex;
  }

  &__item {
    &__img {
      width: 65px;
      height: 48px;
      object-fit: cover;

      border-radius: 4px;
    }
    &__title {
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;

      margin-left: 24px;

      width: 100px;
    }
    &__count {
      margin-right: 20px;

      color: var(--tg-theme-link-color);

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
    &__price {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      width: 54px;

      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__img--plug {
    height: 48px;
    width: 65px;
    background: #CDD0E0;
  }
}