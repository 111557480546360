:root {
  --tg-theme-bg-color: #2E334A;
  --tg-theme-secondary-bg-color: #202334;
  --tg-theme-hint-color: #8B929D;
  --tg-theme-text-color: #fff;
  --tg-theme-button-color: #F78C3B;
  --tg-theme-button-text-color: #fff;
  --tg-theme-link-color: #3AA4F7;
}


/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  overflow-x: hidden;

  margin: 0;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);

  font-family: Rubik, sans-serif;
}