.main-popup__container {
  background-color: var(--tg-theme-bg-color);
  width: 344px;
  border-radius: 4px;

  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);

  padding: 16px;

  z-index: 10;

  .main-popup__header {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: var(--tg-theme-text-color);

    display: flex;
    justify-content: space-between;

    .main-popup__close {
      width: 12px;
      height: 12px;

      cursor: pointer;
    }
  }

  .main-popup__content {
    margin-top: 16px;

    width: 100%;
  }

  .main-popup__action-bottom {
    margin-top: 16px;

    display: flex;
    justify-content: flex-end;

    &__width--full {
      width: 100%;
    }

    &__secondary, &__primary {
      font-size: 14px;

      padding: 8px;

      border-radius: 4px;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__primary {
      background-color: var(--tg-theme-button-color);
      color: var(--tg-theme-button-text-color);
    }
    &__secondary {
      background-color: var(--tg-theme-hint-color);
      color: var(--tg-theme-button-text-color);
    }
  }
}